.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  background:#b0b0b0;
	background: linear-gradient(#ffffff,#e2e0e0);
}

.section {
  padding: 1.5rem;
}

.divider {
  border-bottom: 1px solid #e2e0e0;
  margin-bottom: 15px;
}

.navbar-item img {
  max-height: 4rem;
}
