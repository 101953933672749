@media (max-width: 768px){
  .table tbody tr {
    display: block;
    padding: 10px 15px 10px;
    border-top: 1px solid #ddd;
    position: relative;
  }
  .table tbody tr td {
    padding: 0;
    border: 0;
    text-align: left;
  }

  .table tbody tr td.distance {
    position: absolute;
    left: 15px;
    bottom:10px;
  }
  .table tbody tr td.meeting , .table tbody tr td.location,  .table tbody tr td.address {
    width: 220px;
    display:block;
    text-overflow: ellipsis;
  }
  .table tbody tr td.type {
    position: absolute;
    right: 15px;
    top:13px;
  }
}

.table thead th {
  line-height: 1;
  padding-top: 0;
  text-transform: none;
  cursor: pointer;
  position: relative;
  padding: 7.5px 15px;
  border: 0;
}

.table .sorted-up::after {

  content: "";
    border-style: solid;
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    top: 12px;
    border-width: 8px 4px 0;
    border-color: #aaa transparent transparent;
}
.table .sorted::after {
  content: "";
  border-style: solid;
  width: 0;
  height: 0;
  position: absolute;
  right: 0;
  top: 12px;
  border-width: 0 4px 8px;
  border-color: transparent transparent #aaa;
}
